import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { View, Button, Heading } from "@aws-amplify/ui-react";
import "./subscriptionSettings.scss";
import SubscriptionCategoryTable from "../components/subscriptionManagementPage/subscriptionCategoryTable";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import {
  getUserSubscriptions,
  updateUserSubscription,
} from "../apis/notificationSubscriptionApi";
import NavBar from "../components/navbar/navbar";
import BasicToast from "../components/toastMessage/toast";

const SubscriptionSettings = ({ user, signOut }) => {
  const { t, i18n } = useTranslation("common");
  const [userInfo, setUserInfo] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [subscriptions, setSubscriptions] = useState();
  const [subscriptionGroups, setSubscriptionGroups] = useState();
  const [isToastShown, setIsToastShown] = useState(false);
  const navigate = useNavigate();

  const groupSubscriptionStatusByTrigger = (subscriptions) => {
    const groupedStatusOfTriggerType = subscriptions.reduce(
      (acc, subscription) => {
        const { DeliveryMechanism, IsSubscribed, TriggerType } = subscription;

        let triggerStatus = acc.find((s) => s.triggerType === TriggerType);

        if (!triggerStatus) {
          triggerStatus = {
            triggerType: TriggerType,
            subscribeEmail: null,
            subscribeInApp: null,
          };
          acc.push(triggerStatus);
        }

        if (
          DeliveryMechanism === "Email" &&
          IsSubscribed !== null &&
          IsSubscribed !== undefined
        ) {
          triggerStatus.subscribeEmail = IsSubscribed;
        }

        if (
          DeliveryMechanism === "InApp" &&
          IsSubscribed !== null &&
          IsSubscribed !== undefined
        ) {
          triggerStatus.subscribeInApp = IsSubscribed;
        }

        return acc;
      },
      []
    );

    return groupedStatusOfTriggerType;
  };

  const groupSubscriptionByCategory = (subscriptions) => {
    const groupedSubscriptions = subscriptions.reduce((acc, subscription) => {
      const { TriggerType, DeliveryMechanism, Id, IsSubscribed, NewsCategory } =
        subscription;

      let triggerGroup = acc.find((item) => item.categoryName === NewsCategory);

      if (!triggerGroup) {
        triggerGroup = {
          categoryName: NewsCategory,
          triggers: [],
        };
        acc.push(triggerGroup);
      }

      let trigger = triggerGroup.triggers.find(
        (t) => t.triggerType === TriggerType
      );

      if (!trigger) {
        trigger = {
          triggerType: TriggerType,
          emails: [],
          inApps: [],
        };
        triggerGroup.triggers.push(trigger);
      }

      const subscriptionObj = { id: Id, isSubscribed: IsSubscribed };

      if (DeliveryMechanism === "Email") {
        trigger.emails.push(subscriptionObj);
      } else if (DeliveryMechanism === "InApp") {
        trigger.inApps.push(subscriptionObj);
      }

      return acc;
    }, []);

    return groupedSubscriptions;
  };

  const fetchUserSubscriptions = async () => {
    const subscriptions = await getUserSubscriptions(
      userInfo.id,
      userInfo.companyIdsString
    );

    setSubscriptions(subscriptions);

    const groupedSubscriptions = groupSubscriptionByCategory(subscriptions);
    setSubscriptionGroups(groupedSubscriptions);

    const subscriptionStatus = groupSubscriptionStatusByTrigger(subscriptions);
    setSubscriptionStatus(subscriptionStatus);
  };

  const fetchUserInfo = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const userId = currentUser.attributes["custom:hyt_id"];
      const companyIdsString = currentUser.attributes["custom:company_ids"];
      const userEmail = currentUser.attributes["email"];

      setUserInfo({
        id: userId,
        companyIdsString: companyIdsString,
        email: userEmail,
      });
    } catch (error) {
      throw new Error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (!userInfo) return;
    fetchUserSubscriptions();
  }, [userInfo]);

  const handleCheckboxChange = (event, triggerType, deliveryMechanism) => {
    const isChecked = event.target.checked;
    const updatedStatus = subscriptionStatus.map((subscription) => {
      if (subscription.triggerType === triggerType) {
        if (deliveryMechanism === "Email") {
          return {
            ...subscription,
            subscribeEmail: isChecked,
          };
        } else if (deliveryMechanism === "InApp") {
          return {
            ...subscription,
            subscribeInApp: isChecked,
          };
        }
      }
      return subscription;
    });

    setSubscriptionStatus(updatedStatus);
  };

  const handleSave = async () => {
    const changesList = subscriptionStatus.reduce((acc, status) => {
      const subscriptionList = subscriptions.filter(
        (s) => s.TriggerType === status.triggerType
      );

      subscriptionList.forEach((subscription) => {
        if (
          subscription.DeliveryMechanism === "Email" &&
          status.subscribeEmail !== null &&
          status.subscribeEmail !== subscription.IsSubscribed
        ) {
          acc.push({
            Id: subscription.Id,
            IsSubscribed: status.subscribeEmail,
          });
        } else if (
          subscription.DeliveryMechanism === "InApp" &&
          status.subscribeInApp !== null &&
          status.subscribeInApp !== subscription.IsSubscribed
        ) {
          acc.push({
            Id: subscription.Id,
            IsSubscribed: status.subscribeInApp,
          });
        }
      });

      return acc;
    }, []);

    if (changesList.length === 0) return;

    await updateUserSubscription(userInfo.id, changesList);
    await fetchUserSubscriptions();
    setIsToastShown(true);
  };

  const handleReset = () => {
    const subscriptionStatus = groupSubscriptionStatusByTrigger(subscriptions);
    setSubscriptionStatus(subscriptionStatus);
  };

  const handleGoBack = () => {
    navigate("/home");
  };

  return (
    <View className='page-container'>
      <NavBar user={user} signOut={signOut} />
      <Button className='back-button' onClick={handleGoBack}>
        &lt; {t("SUBSCRIPTIONMANAGEMENT.backToHome")}
      </Button>
      <Heading
        className='text-color-subscription-page'
        level={3}
        textAlign={"center"}
      >
        {t("SUBSCRIPTIONMANAGEMENT.subscriptionSettings")}
      </Heading>

      <View marginTop='large' className='email-section'>
        <label className='text-color-subscription-page '>
          {t("SUBSCRIPTIONMANAGEMENT.emailAddress")} :
        </label>
        <input
          type='text'
          value={userInfo?.email}
          style={{ width: `${userInfo?.email?.length}ch` }}
          disabled
        />
      </View>
      <View className='category-container '>
        {subscriptionGroups &&
          subscriptionGroups.map((subscriptionGroup) => (
            <SubscriptionCategoryTable
              key={subscriptionGroup.categoryName}
              showHeader={subscriptionGroups.indexOf(subscriptionGroup) === 0}
              categoryName={subscriptionGroup.categoryName}
              triggers={subscriptionGroup.triggers}
              subscriptionStatus={subscriptionStatus}
              handleCheckboxChange={handleCheckboxChange}
            />
          ))}
      </View>
      {subscriptions ? (
        <View className='bottom-button-container'>
          <Button
            onClick={handleSave}
            variation='default'
            className='save-button'
          >
            {t("SUBSCRIPTIONMANAGEMENT.save")}
          </Button>
          <Button
            onClick={handleReset}
            variation='link'
            className='reset-button'
          >
            {t("SUBSCRIPTIONMANAGEMENT.reset")}
          </Button>
        </View>
      ) : (
        <div>{t("SUBSCRIPTIONMANAGEMENT.noActiveSubscription")} </div>
      )}
      <BasicToast
        isShown={isToastShown}
        setIsShown={setIsToastShown}
        bodyMessage={t("SUBSCRIPTIONMANAGEMENT.saveSuccess")}
      />
    </View>
  );
};

export default SubscriptionSettings;

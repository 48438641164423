import React from "react";
import { CheckboxField } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { getNotificationTranlation } from "../../utilities/utils";

const SubscriptionCategoryTable = ({
  categoryName,
  triggers,
  subscriptionStatus,
  handleCheckboxChange,
  showHeader,
}) => {
  const { t, i18n } = useTranslation("common");
  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: "10vw" }}>
            {showHeader ? t("SUBSCRIPTIONMANAGEMENT.categoryName") : ""}
          </th>
          <th style={{ width: "10vw" }}>
            {showHeader ? t("SUBSCRIPTIONMANAGEMENT.triggerType") : ""}
          </th>
          <th style={{ width: "5vw" }}>
            {showHeader ? <FontAwesomeIcon icon={faEnvelope} size='sm' /> : ""}
          </th>
          <th style={{ width: "5vw" }}>
            {showHeader ? <FontAwesomeIcon icon={faBell} size='sm' /> : ""}
          </th>
        </tr>
      </thead>

      <tbody>
        {triggers.map((item, index) => (
          <tr key={item.triggerType}>
            <td>
              {index === 0 ? getNotificationTranlation(t, categoryName) : ""}
            </td>
            <td> {getNotificationTranlation(t, item.triggerType)}</td>
            <td>
              <CheckboxField
                checked={
                  subscriptionStatus.find(
                    (subscription) =>
                      subscription.triggerType === item.triggerType
                  )?.subscribeEmail ?? false
                }
                isDisabled={
                  subscriptionStatus.find(
                    (subscription) =>
                      subscription.triggerType === item.triggerType
                  )?.subscribeEmail == null
                }
                onChange={(event) =>
                  handleCheckboxChange(event, item.triggerType, "Email")
                }
              />
            </td>
            <td>
              <CheckboxField
                checked={
                  subscriptionStatus.find(
                    (subscription) =>
                      subscription.triggerType === item.triggerType
                  )?.subscribeInApp ?? false
                }
                isDisabled={
                  subscriptionStatus.find(
                    (subscription) =>
                      subscription.triggerType === item.triggerType
                  )?.subscribeInApp == null
                }
                onChange={(event) =>
                  handleCheckboxChange(event, item.triggerType, "InApp")
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SubscriptionCategoryTable;
